import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card} from "react-bootstrap";
import "./Gallery.scss";
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import galleryImg1D from "../../../images/Static/gallery-img1-d.jpg";
import galleryImg1T from "../../../images/Static/gallery-img1-t.jpg";
import galleryImg1M from "../../../images/Static/gallery-img1-m.jpg";

import galleryImg2D from "../../../images/Static/gallery-img2-d.jpg";
import galleryImg2T from "../../../images/Static/gallery-img2-t.jpg";
import galleryImg2M from "../../../images/Static/gallery-img1-m.jpg";
import {GalleryItem} from "./GalleryItem";
const Gallery = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }; 

  const refLink = useRef(null);
  const [list,setList] = useState(GalleryItem);

  const fliterList = (e={},filterTag) =>{
    e.preventDefault();
    document.querySelector(".active-filter-tab").classList.remove("active-filter-tab");
    e.currentTarget.classList.add("active-filter-tab")  

    if(filterTag === "all")  {
      setList(GalleryItem.slice()) 
    } else {
      let newList = GalleryItem.slice().filter(v => v.keyTag === filterTag);
      setList(newList)
    }
  }
  
  return (
    <React.Fragment>
      <section className="gallery">
        <Container>
        <Row>
          <Col lg={12}>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
            <h2>We’ve got lots of other great content for you...</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit aenean.</p>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="button-filter">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
              <Link href="#" className="d-xl-none">All Stories <i className="icon-plus-white"></i></Link>
            </ScrollAnimation>

            </div>
          </Col>
        </Row>
        </Container>
      </section>
      <section className="gallery-blog">

        <Container>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
          <div className="filters-block d-none d-xl-flex">
            <ul>
              <li ref={refLink} className="active-filter-tab" onClick={ e => fliterList(e,"all")}><Link href="#!">All Stories</Link></li>
              <li ref={refLink} onClick={ e =>fliterList(e,"word")}><Link href="#!">Words</Link></li>
              <li ref={refLink} onClick={ e =>fliterList(e,"market")}><Link href="#!">Market</Link></li>
              <li ref={refLink} onClick={ e =>fliterList(e,"inspiration")}><Link href="#!">Inspiration</Link></li>
              <li ref={refLink} onClick={ e =>fliterList(e,"local")}><Link href="#!">Local</Link></li>
            </ul>
          </div>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp" className="d-xl-none">
            <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={false}
              swipeable={true}
              infinite={true}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {GalleryItem.map((item, index) => ( <div className="item" key={index}>
                <a to="#">
                  
                  <picture>
                    <source media="(min-width:992px)" srcSet={item.ImgGalleryItemD} />
                    <source media="(min-width:768px)" srcSet={item.ImgGalleryItemD} />
                    <img src={item.ImgGalleryItemD} alt="" />
                  </picture>
                  
                </a>
                <div class="cat-name">
                  <span>{item.CategoryGalleryItem}</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">{item.DateGalleryItem}</p>
                  <p className="blog-description">
                    <a href="#">
                    {item.TitleGalleryItem}
                    </a>
                  </p>
                </div>
              </div>
              ))}
              
               </Carousel>
           </ScrollAnimation>
          </Container>
      </section>
      <section className="filter-gallery-section">
      <Container className="d-none d-xl-block">
      <div className="filter-item-block">
            {list.map((item, index) => ( <div className="item"><ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                <a to="#">
                <div className="filter-img-block">
                  <picture>
                    <source media="(min-width:992px)" srcSet={item.ImgGalleryItemD} />
                    <source media="(min-width:768px)" srcSet={item.ImgGalleryItemD} />
                    <img src={item.ImgGalleryItemD} alt="" />
                  </picture>
                  </div>
                </a>
                <div class="cat-name">
                  <span>{item.CategoryGalleryItem}</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">{item.DateGalleryItem}</p>
                  <p className="blog-description">
                    <a href="#">
                    {item.TitleGalleryItem}
                    </a>
                  </p>
                </div>
                </ScrollAnimation>
              </div>
              
              ))}
            
          </div>
      </Container>
      
        
      </section>
    </React.Fragment>
  );
};
export default Gallery;
