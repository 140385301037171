

import galleryImg1D from "../../../images/Static/gallery-img1-d.jpg";
import galleryImg1T from "../../../images/Static/gallery-img1-t.jpg";
import galleryImg1M from "../../../images/Static/gallery-img1-m.jpg";

import galleryImg2D from "../../../images/Static/gallery-img2-d.jpg";
import galleryImg2T from "../../../images/Static/gallery-img2-t.jpg";
import galleryImg2M from "../../../images/Static/gallery-img1-m.jpg";

import galleryImg3D from "../../../images/Static/gallery-img3-d.jpg";
import galleryImg3T from "../../../images/Static/gallery-img1-t.jpg";
import galleryImg3M from "../../../images/Static/gallery-img1-m.jpg";

export const GalleryItem = [
  {
    ImgGalleryItemD:galleryImg1D,
    ImgGalleryItemT:galleryImg1T,
    ImgGalleryItemM:galleryImg1M,
    CategoryGalleryItem:"Case Study",
    DateGalleryItem:"12 November 2020",
    TitleGalleryItem:"A Guide to the Cost of Moving for Homebuyers",
    keyTag: "inspiration"
  },
  {
    ImgGalleryItemD:galleryImg2D,
    ImgGalleryItemT:galleryImg2T,
    ImgGalleryItemM:galleryImg2M,
    CategoryGalleryItem:"News",
    DateGalleryItem:"09 November 2020",
    TitleGalleryItem:"Four Reasons Why We Should Do Our Bit for Small Businesses",
    keyTag: "word"
  },
  {
    ImgGalleryItemD:galleryImg3D,
    ImgGalleryItemT:galleryImg2T,
    ImgGalleryItemM:galleryImg2M,
    CategoryGalleryItem:"Case Study",
    DateGalleryItem:"07 November 2020",
    TitleGalleryItem:"Letter from Santa",
    keyTag: "market"
  },

  {
    ImgGalleryItemD:galleryImg1D,
    ImgGalleryItemT:galleryImg1T,
    ImgGalleryItemM:galleryImg1M,
    CategoryGalleryItem:"Case Study",
    DateGalleryItem:"12 November 2020",
    TitleGalleryItem:"A Guide to the Cost of Moving for Homebuyers",
    keyTag: "word"
  },
  {
    ImgGalleryItemD:galleryImg2D,
    ImgGalleryItemT:galleryImg2T,
    ImgGalleryItemM:galleryImg2M,
    CategoryGalleryItem:"News",
    DateGalleryItem:"09 November 2020",
    TitleGalleryItem:"Four Reasons Why We Should Do Our Bit for Small Businesses",
    keyTag: "inspiration"
  },
  {
    ImgGalleryItemD:galleryImg3D,
    ImgGalleryItemT:galleryImg2T,
    ImgGalleryItemM:galleryImg2M,
    CategoryGalleryItem:"Case Study",
    DateGalleryItem:"07 November 2020",
    TitleGalleryItem:"Letter from Santa",
    keyTag: "local"
  },
]

     
              {/* <div className="item">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={galleryImg1D} />
                    <source media="(min-width:768px)" srcSet={galleryImg1T} />
                    <img src={galleryImg1M} alt="" />
                  </picture>
                </a>
                <div class="cat-name">
                  <span>Case Study</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">12 november 2020</p>
                  <p className="blog-description">
                    <a href="#">
                    A Guide to the Cost of Moving for Homebuyers
                    </a>
                  </p>
                </div>
              </div> */}

              {/* <div className="item">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={galleryImg2D} />
                    <source media="(min-width:768px)" srcSet={galleryImg2T} />
                    <img src={galleryImg2M} alt="" />
                  </picture>
                </a>
                <div class="cat-name">
                  <span>News</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">09 November 2020</p>
                  <p className="blog-description">
                    <a href="#">
                    Four Reasons Why We Should Do Our Bit for Small Businesses
                    </a>
                  </p>
                </div>
              </div>
              <div className="item">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={galleryImg1D} />
                    <source media="(min-width:768px)" srcSet={galleryImg1T} />
                    <img src={galleryImg1M} alt="" />
                  </picture>
                </a>
                <div class="cat-name">
                  <span>Case Study</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">07 November 2020</p>
                  <p className="blog-description">
                    <a href="#">
                    Letter from Santa
                    </a>
                  </p>
                </div>
              </div>
              <div className="item">
                <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={galleryImg1D} />
                    <source media="(min-width:768px)" srcSet={galleryImg1T} />
                    <img src={galleryImg1M} alt="" />
                  </picture>
                </a>
                <div class="cat-name">
                  <span>Case Study</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">12 november 2020</p>
                  <p className="blog-description">
                    <a href="#">
                    A Guide to the Cost of Moving for Homebuyers
                    </a>
                  </p>
                </div>
              </div>
              <div className="item"> */}
                {/* <a to="#">
                  <picture>
                    <source media="(min-width:992px)" srcSet={galleryImg1D} />
                    <source media="(min-width:768px)" srcSet={galleryImg1T} />
                    <img src={galleryImg1M} alt="" />
                  </picture>
                </a>
                <div class="cat-name">
                  <span>Case Study</span>
                </div>
                <div className="blog-content">
                  <p className="blog-date">12 november 2020</p>
                  <p className="blog-description">
                    <a href="#">
                    A Guide to the Cost of Moving for Homebuyers
                    </a>
                  </p>
                </div>
              </div> */}
          

