import React,{useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import StaticBanner from "../../Components/Static/StaticBanner/StaticBanner";
import Gallery from "../../Components/Static/Gallery/Gallery";
import ContactTeam from "../../Components/Static/ContactTeam/ContactTeam";

const StaticPage = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  
  return (
    <React.Fragment>
      <div className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}><Header/></div>
        <StaticBanner/>
        <Gallery/>
        <ContactTeam/>
      <div className="footer-property-management"><Footer/></div>
    </React.Fragment>
    );
};

export default StaticPage;
