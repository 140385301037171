import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
} from "react-bootstrap"
import "./StaticBanner.scss"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

import userImgDesk from "../../../images/Static/stamp-img.png"
import userImgTab from "../../../images/Static/stamp-img.png"
import userImgMob from "../../../images/Static/stamp-img.png"

import articleVideoImgDesk from "../../../images/Static/article-image-d.jpg"
import articleVideoImgTab from "../../../images/Static/article-image-t.jpg"
import articleVideoImgMob from "../../../images/Static/article-image-m.jpg"

import viewingSafelyImgDesk from "../../../images/Static/viewing-safely-img-d.jpg"
import viewingSafelyImgTab from "../../../images/Static/viewing-safely-img-t.jpg"
import viewingSafelyImgMob from "../../../images/Static/viewing-safely-img-m.jpg"

import additionalSafetyImgDesk from "../../../images/Static/additonal-safety-img-d.jpg"
import additionalSafetyImgTab from "../../../images/Static/additonal-safety-img-t.jpg"
import additionalSafetyImgMob from "../../../images/Static/additonal-safety-img-m.jpg"

import additionalSafetyImg2Desk from "../../../images/Static/additonal-safety-img-d2.jpg"
import additionalSafetyImg2Tab from "../../../images/Static/additonal-safety-img-t2.jpg"
import additionalSafetyImg2Mob from "../../../images/Static/additonal-safety-img-t2.jpg"
import PlayVideo from "../../Play/PlayVideo"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import { useLocation } from "@reach/router"
const StaticBanner = props => {
  const trackerShare = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareUrl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  const location = useLocation()
  const shareUrl = location.href
  const [isPlay, setPlay] = useState(false)

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={
          isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
        }
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={
            isCurrentEventKey ? "icon-minus-accordion " : "icon-plus-accordion"
          }
        ></i>
      </button>
    )
  }

  return (
    <React.Fragment>
      <section className="static-page-Banner">
        <Container>
          <ScrollAnimation
            animateOnce={true}
            className="banner-animated"
            animateIn="fadeInUp"
          >
            <div className="static-banner-content">
              <div className="inner-container">
                <h4>12 January 2021</h4>
                <h1>A Lockdown Update for Homeowners and Landlords</h1>
                <div className="user-data">
                  <div className="banner-user-details">
                    {/* <div className="banner-user-img">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={userImgDesk}
                        />
                        <source media="(min-width:768px)" srcSet={userImgTab} />
                        <img src={userImgMob} alt="" />
                      </picture>
                    </div> */}
                    <div className="banner-user-content">
                      <span className="name">Laura White</span>
                      <span className="details">
                        <Link href="tel:02079239222" className="btn-text-link">
                          0207 923 9222
                        </Link>
                        /
                        <Link href="mailto:" className="btn-text-link">
                          Email
                        </Link>
                      </span>
                    </div>
                  </div>

                  <div className="social-media-banner">
                    <span>Share:</span>
                    <ul className="share-trans">
                      <li>
                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                          <FacebookIcon iconFillColor="white" size={32} round={false} borderRadius={`10`} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                          <TwitterIcon size={32} round={false} borderRadius={`10`} />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                          <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Container>
      </section>
      <section className="static-article-data">
        <Container>
          <Row>
            <Col lg={12}>
              <ScrollAnimation
                animateOnce={true}
                className="banner-animated"
                animateIn="fadeIn"
              >
                <div className="video-banner">
                  <picture>
                    <source
                      media="(min-width:992px)"
                      srcSet={articleVideoImgDesk}
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={articleVideoImgTab}
                    />
                    <img src={articleVideoImgMob} alt="" />
                  </picture>
                  <a
                    href="#!"
                    className="video-arrow"
                    onClick={e => {
                      setPlay(true)
                    }}
                  >
                    <i class="icon-video-white"></i>
                  </a>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                <div className="inner-container">
                  <div className="static-article-content">
                    <p className="article-content-bold">
                      Last night The Prime Minister announced that on Wednesday
                      morning the people of England will be going back into
                      lockdown tomorrow following a surge in cases.
                    </p>
                    <p>
                      The tighter restrictions include schools shutting until
                      the February half-term for most pupils. People must now
                      work from home if they can, and only essential shops can
                      remain open.
                    </p>
                    <p>
                      This leads us to how these new measures will affect people
                      wanting and needing to move home. According to the
                      Government’s latest advice issued on its website, people
                      CAN still move home as long as they follow the guidelines
                      in place.
                    </p>
                    <p>
                      At the end of this article, there is a link to the
                      information the Government issued this evening. The
                      section which addresses the property market states:
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className="col-grid3">
            <Col md={4}>
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeInUp"
                duration={0.5}
              >
                <div className="grid-box-content">
                  <h3>50%</h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeInUp"
                duration={0.5}
              >
                <div className="grid-box-content">
                  <h3>&#163;1.4m</h3>
                  <p>Curabitur eu aliquam lacus ut tempor nisi lipsum.</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeInUp"
                duration={0.5}
              >
                <div className="grid-box-content">
                  <h3>231+</h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="inner-container">
                <div className="article-text">
                  <h5 className="article-subheading">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInUp"
                      duration={0.5}
                    >
                      Moving home – the new measures
                    </ScrollAnimation>
                  </h5>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <p>
                      You can still move home. People outside your household or
                      support bubble should not help with moving house unless
                      absolutely necessary. Estate and letting agents and
                      removals firms can continue to work. If you are looking to
                      move, you can go to property viewings.
                    </p>
                    <p>
                      Follow the national guidance on moving home safely, which
                      includes advice on social distancing, letting fresh air
                      in, and wearing a face covering. While these new measures
                      don’t affect home movers too much, what can we do is
                      ensure that we keep each other and our communities as safe
                      as possible.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="article-text ptop">
                  <h5 className="article-subheading">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInUp"
                      duration={0.5}
                    >
                      Viewing safely – urgent movers only
                    </ScrollAnimation>
                  </h5>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <p>
                      The latest Government advice issued on its website says
                      people can still move home - but who should? We saw a
                      sharp rise in the number of sales and rental enquiries
                      over the Christmas break. Over 450 enquiries were dealt
                      with over the two weeks from Dec 18th – Jan 1st.
                    </p>
                    <p>
                      We will continue to help people keep moving during
                      lockdown but, with demand this high, we insist that only
                      urgent movers proceed with physical appointments. Virtual
                      tours are available for those who would like to have a
                      closer look at properties from the comfort & safety of
                      their home and we’re always happy to answer any questions
                      and offer any property related advice.
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn">
                <div className="article-img">
                  <picture>
                    <source
                      media="(min-width:992px)"
                      srcSet={viewingSafelyImgDesk}
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={viewingSafelyImgTab}
                    />
                    <img src={viewingSafelyImgMob} alt="" />
                  </picture>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="inner-container">
                <div className="article-text article-text2">
                  <ScrollAnimation
                    animateOnce={true}
                    animateIn="fadeInUp"
                    duration={0.5}
                  >
                    <h5 className="article-subheading">
                      Additional Safety Measures
                    </h5>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateOnce={true}
                    animateIn="fadeInUp"
                    duration={0.5}
                  >
                    <p>
                      The safety of our community is our number one priority. We
                      are following the government's guidelines and we've added
                      to them. All viewers will have a contact-free temperature
                      check upon arriving for their viewing and gloves and
                      sanitiser will be provided.
                    </p>
                    <p className="mtop">
                      Time for a digestive stroll. Head to the western edge of
                      Victoria Park to find Regent’s Canal bordering the
                      parkland – few places in London offer a more picturesque
                      place to walk.
                    </p>
                    <p>
                      Notice the canal boats that populate the waterways, some
                      are more elaborate than others. It’s only natural to have
                      a favourite but be nice – most of these are people’s
                      homes.
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="inner-container">
                <div className="accordion-block">
                  <ScrollAnimation
                    animateOnce={true}
                    animateIn="fadeInUp"
                    duration={0.5}
                  >
                    <Accordion>
                      <Card>
                        <div className="accordion-header">
                          <ContextAwareToggle
                            eventKey="0"
                            className="accordion-header"
                          >
                            <span>Restaurants & Bars in Hackney</span>
                          </ContextAwareToggle>
                        </div>

                        <Accordion.Collapse eventKey="0">
                          <div className="accordion-card accordion-card-first">
                            <div className="content">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <div className="accordion-header">
                          <ContextAwareToggle eventKey="1">
                            <span>Things to do in Hackney</span>
                          </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="1">
                          <div className="accordion-card accordion-card-second">
                            <div className="content">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <div className="accordion-header">
                          <ContextAwareToggle eventKey="2">
                            <span>Events in Hackney</span>
                          </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="2">
                          <div className="accordion-card accordion-card-third">
                            <div className="content">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </ScrollAnimation>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="additional-safety-img-box">
          <Row>
            <Col md={6} className="pr-xl-4">
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn">
                <div className="grid-images">
                  <picture>
                    <source
                      media="(min-width:992px)"
                      srcSet={additionalSafetyImgDesk}
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={additionalSafetyImgTab}
                    />
                    <img src={additionalSafetyImgMob} alt="" />
                  </picture>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} className="d-none d-md-block pl-xl-4">
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn">
                <div className="grid-images">
                  <picture>
                    <source
                      media="(min-width:992px)"
                      srcSet={additionalSafetyImg2Desk}
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={additionalSafetyImg2Tab}
                    />
                    <img src={additionalSafetyImg2Mob} alt="" />
                  </picture>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeInUp"
                duration={0.5}
              >
                <div className="inner-container">
                  <div className="addtional-safety-content-more">
                    <p>
                      Phasellus malesuada, nunc in suscipit interdum, elit ipsum
                      blandit tortor, sit amet facilisis lectus nisl ac augue.
                      Cras in tincidunt lectus. Praesent et efficitur lorem.
                      Mauris ipsum ante, interdum eget enim ac, mollis dignissim
                      enim. Quisque vel varius augue. Integer placerat ex non
                      dapibus tincidunt.
                    </p>
                    <p>
                      Phasellus luctus malesuada arcu, non mattis neque
                      dignissim ut. Nullam lacinia congue ipsum, quis posuere
                      orci mattis eget. Vivamus posuere nibh quis tellus
                      accumsan, at tempor sem volutpat. Aliquam suscipit ipsum
                      eu risus laoreet lacinia.
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
        <div className="stay-updated-form">
          <Container>
            <Row>
              <Col md={12}>
                <div className="form-section">
                  <div className="form-head">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInLeft"
                      duration={0.5}
                    >
                      <h2>Get all the words straight to your inbox.</h2>
                    </ScrollAnimation>
                  </div>

                  <div className="article-form">
                    <ScrollAnimation
                      animateOnce={true}
                      animateIn="fadeInRight"
                      duration={0.5}
                    >
                      <Form>
                        <div className="form-group-row">
                          <Form.Group controlId="formBasicName">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control
                              type="name"
                              placeholder="Christopher Rowe"
                            />
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="chrisr@starberry.tv"
                            />
                          </Form.Group>
                          <Form.Group className="button-block-form d-none">
                            <Button
                              variant="dark"
                              type="submit"
                              className="d-xxl-block d-none"
                            >
                              Stay Updated
                            </Button>
                          </Form.Group>
                        </div>
                        <Form.Text className="form-text-1">
                          By clicking Stay Updated, you agree to our Terms &
                          Conditions and Privacy &amp; Cookie Policy
                        </Form.Text>

                        <Button
                          variant="dark"
                          type="submit"
                          className="d-xxl-none d-xl-block"
                        >
                          Stay Updated
                        </Button>
                        <Form.Text className="form-text-2">
                          By clicking Stay Updated, you agree to our Terms &
                          Conditions and Privacy &amp; Cookie Policy
                        </Form.Text>
                      </Form>
                    </ScrollAnimation>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId="JFqiSr9A-Go"
          isAutoPlay={1}
        />
      </section>
    </React.Fragment>
  )
}
export default StaticBanner
